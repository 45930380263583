import React from "react";
import Global from "../global/global"
import * as styles from "./single-column.module.scss";

export default function SingleColumn({ wide, bottom }) {

    return (
        <Global>
            <main className={styles.main}>
                <section className={styles.section}>
                    <section className={styles.column}>
                        {wide}
                    </section>
                </section>
            </main>
            <section className={styles.bottom}>
                  {bottom}
            </section>
        </Global>
    )
}
