import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ArticleHeader from "../components/blocks/article-header/article-header";
import FooterTaxonomy from "../components/blocks/footer/footer-taxonomy/footer-taxonomy";
import SingleColumn from "../layouts/single-column/single-column";

export const data = graphql `
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`



export default function PodcastPage({data, pageContext}) {

  const { t } = useTranslation();

  const header = <ArticleHeader
    iconType="library"
    sectionName={t('four_oh_four.title', 'Page not found')}
    showNewsletter={false}
    isHeader={true}
    ></ArticleHeader>



  const mainContent = [ header, <p style={{
    margin: "1rem 0",
    "text-align": "center"
  }}>{t('four_oh_four.content', 'Requested page could not be found')}</p> ];

  const sidebar = '';

  const bottom = <FooterTaxonomy></FooterTaxonomy>

  return (
      <SingleColumn
        wide={mainContent}
        sidebar={sidebar}
        bottom={bottom}
      >
      </SingleColumn>
  );
}
